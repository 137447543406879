import { UseAppStore } from '@/stores/AppStore';
import {App} from "vue";

export class OnlineStatusService {
    static appStore:any = null;

    static initialize(app: App<Element>) {
        this.appStore= UseAppStore()
        this.initializeEventListeners();

        app.config.globalProperties.isOffline = () => OnlineStatusService.isOffline;

    }

    static initializeEventListeners() {
        window.addEventListener('online', this.updateOnlineStatus.bind(this));
        window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    }

    static updateOnlineStatus() {
        this.appStore.setOfflineStatus(!navigator.onLine);
    }

    static get isOffline() {
        return this.appStore.isOffline;
    }

}