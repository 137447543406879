import { createI18n } from "vue-i18n";
// @ts-ignore
import de from './de';
// @ts-ignore
import en from './en';


const messages = {
  "en-DE": en,
  "de-DE": de
};

const i18n  = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") || "en-DE",
  messages,
});

export default i18n;
