<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button href="/home" tab="home">
          <ion-icon :icon="home" />
          <ion-label>{{ $t("subMenu.home") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button href="/leads" tab="leads">
          <ion-icon :icon="personAdd" />
          <ion-label>{{ $t("subMenu.leads") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button href="/products" tab="Infomaterial">
          <ion-icon :icon="folder" />
          <ion-label>{{ $t("subMenu.infomaterial") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          v-show="screenWidth > 590"
          href="/website"
          tab="website"
        >
          <ion-icon :icon="globeOutline" />
          <ion-label>{{ $t("subMenu.website") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          v-show="screenWidth > 590"
          href="/w-products"
          tab="website-products"
        >
          <ion-icon :icon="pricetag" />
          <ion-label>{{ $t("subMenu.products") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button v-show="screenWidth > 590" href="/news" tab="news">
          <ion-icon :icon="newspaperOutline" />
          <ion-label>{{ $t("subMenu.news") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          v-show="screenWidth > 590"
          href="/application-database"
          tab="Application-database"
        >
          <ion-icon :icon="albumsOutline"></ion-icon>
          <ion-label>{{ $t("subMenu.appDb") }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
} from "@ionic/vue";

import {
  home,
  albumsOutline,
  personAdd,
  folder,
  search,
  newspaperOutline,
  globeOutline,
  pricetag,
} from "ionicons/icons";

export default {
  components: {
    IonPage,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
  },
  data() {
    return {
      home,
      personAdd,
      folder,
      search,
      albumsOutline,
      newspaperOutline,
      globeOutline,
      pricetag,
    };
  },

  /**
   * By default, vue router caches pages that can be reused later.
   * Due to logic on my side, I need to force the router to unmount the ProductsPage component.
   * All other pages are cached.
   */
  computed: {
    screenWidth() {
      return window.innerWidth;
    },

    routerOutletKey() {
      // Replace 'Products' with the route name of the component you want to unmount
      if (this.$route.name === "Products") {
        return this.$route.fullPath;
      }
      // For other routes, use a constant key so that the components are reused
      return "constant-key";
    },
  },
};
</script>
