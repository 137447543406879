<template>
  <svg
      data-name="Layer 1"
      viewBox="0 0 116.42 122.88"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        class="cls-1"
        d="M88.08,60.79c10.53,6.67,20,9.83,28.22,9.09,1.42,28.85-9.23,45.89-28.11,53-18.24-6.66-29-23-28.23-53.44a40.39,40.39,0,0,0,28.12-8.65ZM62.9,12.13l20.72,19.4H62.9V12.13ZM21.27,56H63.14c2.74,0,3.1,3.75,1.32,5-.83.15-1.65.28-2.48.39H21.3c-3.22,0-3.22-5.33,0-5.38Zm0,16.74H50.46c.08,1.84.21,3.63.37,5.38H21.3c-3.24,0-3.2-5.38,0-5.38Zm0,16.74H52.48c.38,1.85.81,3.65,1.29,5.38H21.3c-3.24,0-3.2-5.38,0-5.38Zm0-50.22H45.36c3.24,0,3.2,5.37,0,5.37H21.3c-3.24,0-3.21-5.37,0-5.37Zm0-15.57H34.52c3.19,0,3.23,5.37,0,5.37H21.3c-3.24,0-3.2-5.37,0-5.37ZM95.15,34.32A3.44,3.44,0,0,0,92.64,31L62.12,1.27A3.43,3.43,0,0,0,59.45,0H6.2A6.2,6.2,0,0,0,0,6.2V112.35a6.21,6.21,0,0,0,6.2,6.2H66a51.93,51.93,0,0,1-5.08-6.93h-54V6.89H56V35a3.47,3.47,0,0,0,3.47,3.48H88.22V50.33q3.52,2.22,6.93,4.05V34.32ZM88.3,70.66c7.19,4.55,13.68,6.71,19.25,6.2,1,19.68-6.3,31.3-19.17,36.14a2.34,2.34,0,0,1-.24-.09V70.79l.16-.13Zm-.21-6.31c9.32,5.91,17.75,8.71,25,8.05,1.26,25.54-8.17,40.62-24.89,46.91C72,113.42,62.49,99,63.2,72a35.74,35.74,0,0,0,24.89-7.66Z"
    />
  </svg>
</template>