<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="flex-shrink-0 w-6 h-6"
      aria-hidden="true"
      focusable="false"
  >
    <path d="M10 4H5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V5a1 1 0 00-1-1zm0 6H5V5h5zm0 3H5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm0 6H5v-5h5zm9-15h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V5a1 1 0 00-1-1zm0 6h-5V5h5zm0 3h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm0 6h-5v-5h5z" fill="currentColor"></path>
  </svg>
</template>