/* eslint-disable no-console */
import { register } from "register-service-worker";


  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
          "Service worker ready"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {

      if (confirm("New content is available. Do you want to refresh the page? We strongly suggest you do so.")) {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            // Unregister all service workers
            const unregisterPromises = registrations.map(reg => reg.unregister());

            Promise.all(unregisterPromises).then(() => {

              if (caches && caches.keys) {
                // Clear all caches
                caches.keys().then((keys) => {
                  const cacheClearPromises = keys.map(key => caches.delete(key));

                  Promise.all(cacheClearPromises).then(() => {
                    // Refresh the page to reload all assets
                    window.location.reload();
                  }).catch(error => {
                    console.error("Error clearing caches:", error);
                  });
                });
              }
            }).catch(error => {
              console.error("Error unregistering service worker:", error);
            });
          });
        }
      }
    },
    offline() {
      console.log(
          "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
