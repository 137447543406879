import axios, {AxiosInstance} from 'axios';
import storage from "@/storage";


const api: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

(async () => {
    const token = await storage.get("auth.token");
    if (token) {
        // If a Bearer token is available, include it in the header.
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
})();

export default api;