import {AxiosInstance, AxiosResponse} from "axios";
import { inject } from "vue";
import {presentToast} from "@/utils/helpers";

export default class ApiService {
    private api: AxiosInstance;

    constructor(axios:any = null) {
        //Support for passing axios instance
        if (axios) {
            this.api = axios;
            return;
        }
        // If no axios instance is passed, try to get it from the container
        const axiosInstance = inject<AxiosInstance>("axios");
        if (!axiosInstance) {
            throw new Error("Axios instance not found");
        }
        this.api = axiosInstance;
    }

    getApiInstance() {
        return this.api;
    }


    async getCountries(successCallback?: (data: AxiosResponse<any> ) => void , errorCallback = null) {
        this.api.get("get-countries").then((response) => {
            if (successCallback)

            successCallback(response.data);
        }).catch((error) => {
            console.log(error)
        });
    }


    async getTradeshows(successCallback?: (data: AxiosResponse<any> ) => void , errorCallback = null) {
        this.api.get("get-tradeshows").then((response) => {
            if (successCallback)

            successCallback(response.data);
        }).catch((error) => {
            console.log(error)
        });
    }

    async getDepartments(successCallback?: (data: AxiosResponse<any> ) => void , errorCallback = null) {
        this.api.get("get-departments").then((response) => {
            if (successCallback)
              successCallback(response.data);
        }).catch((error) => {
            console.log(error)
        });
    }
}