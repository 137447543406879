<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="flex-shrink-0 w-6 h-6"
      aria-hidden="true"
      focusable="false"
  >
    <path d="M15.993 21.001a.5.5 0 01-.5-.5c0-2.778-2.775-5.886-6.491-5.886s-6.51 3.107-6.51 5.886a.5.5 0 01-1 0 7.189 7.189 0 015.154-6.51 4.809 4.809 0 114.7 0 7.371 7.371 0 015.143 6.508.5.5 0 01-.5.5zM9.001 5.991A3.808 3.808 0 1012.808 9.8a3.814 3.814 0 00-3.807-3.808zm8.366 6.477a4.808 4.808 0 10-5.155-8.1 5.8566 5.8566 0 01.88.63 3.7182 3.7182 0 011.925-.525 3.808 3.808 0 01-.005 7.616 1.2309 1.2309 0 01-.135 0 6.4303 6.4303 0 01-.375.8 2.4644 2.4644 0 01-.15.245 5.8044 5.8044 0 01.665-.035c3.715 0 6.49 3.11 6.49 5.885a.5.5 0 101 0 7.373 7.373 0 00-5.1399-6.516z" fill="currentColor"></path>
  </svg>
</template>