<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="flex-shrink-0 w-6 h-6"
      aria-hidden="true"
      focusable="false"
  >
    <path d="M19.282,5.781h-7.48l-.973-1.634a1.76,1.76,0,0,0-1.511-.784c-1.478-.008-2.955-.009-4.4,0a1.749,1.749,0,0,0-1.9,1.561c-.005.052-.007.1-.008.157V16.672A1.792,1.792,0,0,0,4.797,18.33a1.19124,1.19124,0,0,0,.133-.005c.045-.005.067-.005.1-.01h6.32a.5.5,0,0,0,0-1H5.006c-.632,0-.959-.24-1-.728V7.774a1,1,0,0,1,.995-1H19.286c.839,0,1.015.385,1.015.709v3.1a.574.574,0,0,0,.5.562.461.461,0,0,0,.481-.435.57362.57362,0,0,0,.02-.139v-3.089C21.295,6.7,20.768,5.781,19.282,5.781Zm-8.645,0H4.988a2.00008,2.00008,0,0,0-.995.272V5.084c0-.165.061-.69.826-.721h.075c1.471-.011,2.942-.007,4.431,0a.731.731,0,0,1,.659.322ZM20.95,17.287a.5.5,0,0,0-.707-.008l-1.876,1.84V12.884a.5.5,0,0,0-1,0v6.123l-1.759-1.725a.50031.50031,0,0,0-.7.715l2.666,2.614a.4845.4845,0,0,0,.128.092h0a.50788.50788,0,0,0,.213.05h0a.49987.49987,0,0,0,.213-.05h0a.46928.46928,0,0,0,.128-.092l2.666-2.614a.52128.52128,0,0,0,.025-.707Z" fill="currentColor"></path>
  </svg>
</template>
