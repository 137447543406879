<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="flex-shrink-0 w-6 h-6"
      aria-hidden="true"
      focusable="false"
  >
    <path d="M19.416 6.5475h-3.3848V4.5378a.5.5 0 10-1 0v2.0097H8.9679V4.5378a.5.5 0 00-1 0v2.0097H4.583a2.0026 2.0026 0 00-2 2v9.4522a2.0026 2.0026 0 002 2h14.833a2.0026 2.0026 0 002-2V8.5475a2.0026 2.0026 0 00-2-2zm1 11.4522a1.0007 1.0007 0 01-1 1H4.583a1.0007 1.0007 0 01-1-1V8.5475a1.0007 1.0007 0 011-1h3.3848v2.9541a.5.5 0 001 0v-2.954h6.0634v2.954a.5.5 0 101 0v-2.954h3.3848a1.0007 1.0007 0 011 1z" fill="currentColor"></path>
  </svg>
</template>
