<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
  >
    <path
        fill="currentColor"
        d="M9.7204 7.6862a.5095.5095 0 00-.123.3272.4552.4552 0 00.1425.3154l3.7402 3.6104.001.001a.0836.0836 0 010 .1035l-3.7168 3.5927-.081.0772a.456.456 0 00.3994.7402.4318.4318 0 00.2881-.1455l3.7725-3.6347a.985.985 0 00-.0273-1.3897l-3.7422-3.6084a.4514.4514 0 00-.1455-.0967l-.0118-.0342-.1591-.0019a.4555.4555 0 00-.337.1435z"
    >
    </path>
  </svg>
</template>