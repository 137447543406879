<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="flex-shrink-0 w-6 h-6"
      aria-hidden="true"
      focusable="false"
  >
    <path d="M10.562 16a1.9126 1.9126 0 10.026 0h-.026zm.01 2.951a.925.925 0 11.92-.93.925.925 0 01-.92.93zm5.76-2.85a1.925 1.925 0 101.36 3.28 1.954 1.954 0 00.56-1.36 1.921 1.921 0 00-1.92-1.92zm0 2.85a.925.925 0 11.92-.93.925.925 0 01-.92.93zM16.6894 15h-5.5215a2.7583 2.7583 0 01-2.4794-1.5296L4.9954 5.7673a1.7764 1.7764 0 00-2.0482-.9027l-2.331.6309a.5018.5018 0 11-.2618-.969l2.331-.6299A2.7904 2.7904 0 015.8951 5.328l3.693 7.7042A1.7573 1.7573 0 0011.168 14h5.5204a1.868 1.868 0 001.587-.8941L20.2397 9h-9.0886a.5.5 0 010-1h9.8633a.5172.5172 0 01.4665.7407l-2.317 4.837A2.8965 2.8965 0 0116.6893 15z" fill="currentColor"></path>
  </svg>
</template>
