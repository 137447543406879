import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabNav from "../components/TabNav.vue";
import storage from "@/storage";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginPage.vue"),
    meta: {
      title: "Login",
      isNotProtectedWithAuth: true,
    },
  },
  {
    path: "/supergeheimer-admin-zugang",
    name: "SuperAdminLogin",
    component: () => import("../views/SuperAdminLoginPage.vue"),
    meta: {
      // title: "SuperAdminLogin",
      title: "Login",
      isNotProtectedWithAuth: true,
    },
  },

  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/",
    component: TabNav,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: () => import("../views/HomePage/HomePage.vue"),
      },
      {
        path: "leads",
        component: () => import("../views/LeadsPage/LeadsList.vue"),
      },
      // {
      //   path: "/lead/:id",
      //   component: () => import("../views/LeadsPage/LeadsEditPage.vue"),
      // },
      {
        path: "/lead/:id?",
        component: () => import("../views/LeadsPage/LeadsPage.vue"),
      },
      {
        path: "search",
        component: () => import("../views/Search/SearchPage.vue"),
      },
      {
        path: "/products",
        component: () => import("../views/Infomaterial/ProductsPage.vue"),
      },
      {
        path: "/website",
        component: () => import("../views/BalluffWebsitePages/WebsitePage.vue"),
      },
      {
        path: "/news",
        component: () => import("../views/BalluffWebsitePages/NewsPage.vue"),
      },
      {
        path: "/application-database",
        component: () => import("../views/BalluffWebsitePages/ApplicationDatabase.vue"),
      },
      {
        path: "/w-products",
        component: () =>
          import("../views/BalluffWebsitePages/ProduktneuheitenPage.vue"),
      },
      {
        path: "/settings",
        name: "Settings",

        component: () =>
            import("../views/Settings/Settings.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/404.vue"),
    meta: {
      title: "404 not found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL ?? "/"),
  routes,
});

// check if the user is authenticated before allowing access to protected routes
router.beforeEach(async (to, from, next) => {
  // Allow the user to go to the login page if they are not authenticated.
  const requiresAuth = to.matched.some(
    (record) => !record.meta.isNotProtectedWithAuth
  );

  if (requiresAuth) {
    const token = await storage.get("auth.token");
    if (!token) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    // the route is not protected, allow access
    next();
  }
});

export default router;
