import IconWifi from "@/components/Icons/IconWifi.vue";
import IconCircleSlash from "@/components/Icons/IconCircleSlash.vue";
import IconBusinessCard from "@/components/Icons/IconBusinessCard.vue";
import IconChevronRight from "@/components/Icons/IconChevronRight.vue";
import IconChevronLeft from "@/components/Icons/IconChevronLeft.vue";
import IconUsers from "@/components/Icons/IconUsers.vue";
import IconCart from "@/components/Icons/IconCart.vue";
import IconGlobe from "@/components/Icons/IconGlobe.vue";
import IconNews from "@/components/Icons/IconNews.vue";
import IconFolder from "@/components/Icons/IconFolder.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconQrCode from "@/components/Icons/IconQrCode.vue";
import IconXMark from "@/components/Icons/IconXMark.vue";
import IconArrowsRotate from "@/components/Icons/IconArrowsRotate.vue";
import IconFileShield from "@/components/Icons/IconFileShield.vue";

const icons = {
    "icon-wifi": IconWifi,
    "icon-circle-slash": IconCircleSlash,
    "icon-business-card": IconBusinessCard,
    "icon-chevron-right": IconChevronRight,
    "icon-chevron-left": IconChevronLeft,
    "icon-users": IconUsers,
    "icon-cart": IconCart,
    "icon-globe": IconGlobe,
    "icon-news": IconNews,
    "icon-folder": IconFolder,
    "icon-plus": IconPlus,
    "icon-qr-code": IconQrCode,
    "icon-x-mark": IconXMark,
    "icon-arrows-rotate": IconArrowsRotate,
    "icon-file-shield": IconFileShield
};

export default icons;