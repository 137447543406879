export default {
  subMenu: {
    home: "Home",
    leads: "Leads",
    website: "Website",
    infomaterial: "Infomaterial",
    products: "Products",
    news: "News",
    appDb: "App DB",
  },
  home: {
    header: "With high standards and personal commitment.",
    subheader:
        "Improve the competitiveness of our customers quickly and result-oriented with innovative solutions.",
    infomaterial: "Infomaterial",
    leads: "Leads",
    website: "Website",
    products: "Products",
    news: "News",
    appDb: "Application Database",
    loggedInAs: "Logged in as",
    goBack: "Go back",
    internet: "Connected",
    noInternet: "No internet connection",
  },
  leadList: {
    title: "Leads",
    search: "Search",
    syncAll: "Sync all leads",
    createNew: "Create new lead",
    name: "Name",
    email: "Email",
    additionalInfo: "Additional information",
    edit: "Edit",
    sync: "Sync",
    delete: "Delete",
    noUnsyncedLeads: "No available leads",
    draft: "Draft",
  },
  searchPage: {
    search: "Search",
    subdomainSearch: "Zero results. Trying email domain search:",
    country: "Country",
    resetForm: "Reset form",
    loading: "Loading",
    company: "Company",
    name: "Name",
    scanQr: "Scan QR Code",
    readBc: "Scan Business Card",
    createNew: "Create new lead from form data",
    createNewFromScan: "Create new lead from scanned data",
    noResults: "No results",
    noResultsMsg:
        "Your search returns no results. Please change the search terms and try again.",
    accounts: "Accounts",
    contacts: "Contacts",
    createLeadForAcc: "Create lead for this account",
    youAreOffline: "You are offline, search functionality is disabled.",
  },
  leadPage: {
    chooseTradeshow: "Choose a tradeshow",
    scanQRCode: "Scan QR Code",
    male: "Male",
    female: "Female",
    name: "Name",
    surname: "Surname",
    department: "Department",
    departmentFreeText: "Department (free text)",
    company: "Company",
    email: "Email",
    street: "Street",
    houseNumber: "House Number",
    zipCode: "Zip Code",
    city: "City",
    country: "Country",
    phone: "Phone",
    mobile: "Mobile",
    category: "Category",
    hotTopics: "Hot Topics",
    additionalMedia: "Additional media",
    notes: "Notes",
    loading: "Loading",
    saveAsDraft: "Save as draft",
    save: "Save",
    categories: {
      callBack: "Call back",
      sendDocuments: "Send documents",
      visit: "Visit",
      quotation: "Quotation",
      sampleShipment: "Sample shipment",
    },
    errors: {
      pleaseChooseACategory: "Please choose a category",
      pleaseCheckFollowingErrors: "Please check the following errors",
      pleaseChooseCountry: "Please choose a country",
      pleaseChooseADepartment: "Please choose a department",
      notes: "Notes are required",
      notesLength: "You need to write at least 10 characters",
      companyLength: "You must enter a maximum of 40 characters in the 'Company' field",
      surname: "Surname is required",
      post_code: "Zip Code is required",
      department: "Department is required",
      company: "Company is required",
      email: "Email is required",
      pleaseChooseTradeshow: "Please select a tradeshow",
      noTradeshowsAvailable:
          "No tradeshows are available. Please add some first!",
      country: "Country is required",
    },
  },
  drawingBoard: {
    save: "Save",
    clear: "Clear",
    close: "Close",
    title: "Drawing board",
  },
  productSelector: {
    documentSearch: "Document search",
  },
  page404: {
    notFound: "Page not found",
    goBackHome: "Back to homepage",
  },
  settings: {
    settings: "Settings",
    resetWorker: "Reset service worker",
    resetLeadData: "Reset lead data",
    logout: "Logout",
  },
  loginPage: {
    loggingIn: "Logging in...",
    loginMicrosoft: "Login with Microsoft",
  },
  errors: {
    somethingWrong: "Something went wrong, try again",
  },
  c4cMapping: {
    prospect: "Prospect",
    prospectiveCustomer: "Prospective customer-web",
    customer: "Customer",
    consignee: "Consignee",
    payer: "Payer",
    invoiceAddress: "Invoice Address",
    distributor: "Distributor",
    hierarchyNode: "Hierarchy Node",
    project: "Project",
    consignmentStock: "Consignment-stock",
    variousCustomers: "Various Customers",
    shipToParty: "Ship-To Party",
  },
  login: "Login",
  subdomainSearch: "Search by subdomain",
  scannedData: "Scanned Data",
  contactDetails: "Contact Details",

}