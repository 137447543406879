export default {
  subMenu: {
    home: "Startseite",
    leads: "Leads",
    website: "Webseite",
    infomaterial: "Infomaterial",
    products: "Produkte",
    news: "Aktuelles",
    appDb: "Applikationsdatenbank",
    goBack: "Zurück"
  },
  home: {
    header: "Mit hohen Standards und persönlichem Engagement.",
    subheader: "Verbessern Sie die Wettbewerbsfähigkeit unserer Kunden schnell und zielorientiert mit innovativen Lösungen.",
    infomaterial: "Infomaterial",
    leads: "Leads",
    website: "Webseite",
    products: "Produkte",
    news: "Aktuelles",
    appDb: "Applikationsdatenbank",
    loggedInAs: "Angemeldet als",
    goBack: "Zurück",
    internet: "Verbunden",
    noInternet: "Nicht verbunden"
  },
  leadList: {
    title: "Leads",
    search: "Suche",
    syncAll: "Alle Leads synchronisieren",
    createNew: "Neuen Lead erstellen",
    name: "Name",
    email: "E-Mail",
    additionalInfo: "Zusätzliche Informationen",
    edit: "Bearbeiten",
    sync: "Synchronisieren",
    delete: "Löschen",
    noUnsyncedLeads: "Keine unsynchronisierten Leads",
    draft: "Entwurf"
  },
  searchPage: {
    subdomainSearch: "Keine Ergebnisse. Versuche E-Mail-Domänensuche:",
    search: "Suche",
    country: "Land",
    resetForm: "Formular zurücksetzen",
    loading: "Wird geladen",
    company: "Unternehmen",
    name: "Name",
    scanQr: "QR-Code scannen",
    readBc: "Visitenkarte scannen",
    createNew: "Erstellen Sie einen neuen Lead aus Formulardaten",
    createNewFromScan: "Erstellen Sie einen neuen Lead aus gescannten Daten",
    noResults: "Keine Ergebnisse",
    noResultsMsg: "Ihre Suche ergibt keine Ergebnisse. Bitte ändern Sie die Suchbegriffe und versuchen Sie es erneut.",
    accounts: "Accounts",
    contacts: "Kontakte",
    createLeadForAcc: "Lead für diesen Account erstellen",
    youAreOffline: "Sie sind offline, die Suchfunktion ist deaktiviert."
  },
  leadPage: {
    chooseTradeshow: "Wählen Sie eine Messe aus",
    scanQRCode: "QR-Code scannen",
    male: "Männlich",
    female: "Weiblich",
    name: "Name",
    surname: "Nachname",
    department: "Abteilung",
    pleaseChooseADepartment: "Bitte wählen Sie eine Abteilung.",
    departmentFreeText: "Abteilung (Freitext)",
    company: "Unternehmen",
    email: "E-Mail",
    street: "Straße",
    houseNumber: "Hausnummer",
    zipCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    pleaseChooseCountry: "Bitte wählen Sie ein Land.",
    phone: "Telefon",
    mobile: "Mobiltelefon",
    category: "Kategorie",
    pleaseChooseACategory: "Bitte wählen Sie eine Kategorie.",
    hotTopics: "Aktuelle Themen",
    additionalMedia: "Zusätzliche Medien",
    notes: "Notizen",
    pleaseCheckFollowingErrors: "Bitte überprüfen Sie die folgenden Fehler",
    loading: "Wird geladen",
    saveAsDraft: "Als Entwurf speichern.",
    save: "Speichern",
    categories: {
      callBack: "Rückruf",
      sendDocuments: "Dokumente senden",
      visit: "Besuch",
      quotation: "Angebot",
      sampleShipment: "Musterlieferung"
    },
    errors: {
      pleaseChooseACategory: "Bitte wählen Sie eine Kategorie aus",
      pleaseCheckFollowingErrors: "Bitte überprüfen Sie die folgenden Fehler",
      pleaseChooseCountry: "Bitte wählen Sie ein Land aus",
      pleaseChooseADepartment: "Bitte wählen Sie eine Abteilung aus",
      notes: "Notizen sind erforderlich",
      notesLength: "Sie müssen mindestens 10 Zeichen schreiben",
      companyLength: "Sie müssen für das Feld 'Unternehmen' maximal 40 Zeichen schreiben",
      surname: "Nachname ist erforderlich",
      post_code: "Postleitzahl ist erforderlich",
      department: "Abteilung ist erforderlich",
      company: "Firma ist erforderlich",
      email: "E-Mail ist erforderlich",
      pleaseChooseTradeshow: "Bitte wählen Sie eine Messe aus",
      noTradeshowsAvailable: "Es sind keine Messen verfügbar. Bitte fügen Sie zuerst einige hinzu!",
      country: "Land ist erforderlich"
    }
  },
  drawingBoard: {
    save: "Speichern",
    clear: "Löschen",
    close: "Schließen",
    title: "Zeichenbrett"
  },
  productSelector: {
    documentSearch: "Dokumentensuche"
  },
  page404: {
    notFound: "Seite nicht gefunden",
    goBackHome: "Zurück zur Startseite"
  },
  settings: {
    settings: "Einstellungen",
    resetWorker: "Service-Worker zurücksetzen",
    resetLeadData: "Lead-Daten zurücksetzen",
    logout: "Abmelden"
  },
  loginPage: {
    loggingIn: "Anmeldung läuft...",
    loginMicrosoft: "Mit Microsoft anmelden"
  },
  errors: {
    somethingWrong: "Etwas ist schief gelaufen, versuchen Sie es erneut"
  },
  c4cMapping: {
    prospect: "Prospect",
    prospectiveCustomer: "Prospective customer-web",
    customer: "Customer CRM",
    consignee: "Consignee",
    payer: "Payer",
    invoiceAddress: "Invoice Address",
    distributor: "Distributor",
    hierarchyNode: "Hierarchy Node",
    project: "Project",
    consignmentStock: "Consignment-stock",
    variousCustomers: "Various Customers",
    shipToParty: "Ship-To Party"
  },
  contactDetails: "Kontaktdaten",
  login: "Anmeldung",
  subdomainSearch: "Suche nach Subdomain",
  scannedData: "Gescannte Daten"
}