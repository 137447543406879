import { createApp } from "vue";
import { IonicVue } from "@ionic/vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import api from "./lib/axios";
import icons from "@/components/Icons/config/icons";
import {registerIconsGlobally} from "@/components/Icons/config/iconRegister";
import {IconMap} from "@/components/Icons/config/iconMap";
import "@ionic/vue/css/core.css";
// @ts-ignore
import i18n from './i18n/translations';
import "./registerServiceWorker";
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "./theme/tailwind.scss";
import { OnlineStatusService } from './services/OnlineServiceStatus';
import * as Sentry from "@sentry/vue";

// Remove Animate and swipe back gesture
// Because we are using PWA instead of native app
// That causes animation problems on iOs because ios has swipe back
// animation by default that looks the same as the one we have from ionic
const app = createApp(App).use(i18n).use(IonicVue,
    {
        animated: false,
        swipeBackEnabled: false,
    }).use(router);

if (process.env.VUE_APP_ENVIRONMENT && process.env.VUE_APP_ENVIRONMENT !== "local") {
  Sentry.init({
    app,
    dsn: "https://5a9de5cf5ad3a81c990a81aa298968e0@o1038043.ingest.sentry.io/4506269519314944",
    tracePropagationTargets: ["localhost:8100", /^https:\/\/sales-app\.balluff\.com/, /^https:\/\/sales-app\.stage\.balluff\.com/],
    environment: process.env.VUE_APP_ENVIRONMENT ?? 'production',
  });
}

registerIconsGlobally(app, icons as IconMap);

app.use(createPinia());

app.provide("axios", api);

//Initialize the online status service
OnlineStatusService.initialize(app)

router.isReady().then(() => {
  app.mount("#app");
});

